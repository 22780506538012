import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import '../Style/Properties.css';
import { FaAnglesRight, FaAnglesLeft } from "react-icons/fa6";
import Form from 'react-bootstrap/Form';
import { useNavigate, Link } from 'react-router-dom';
import { Button, Row, Col } from 'react-bootstrap';

function Signedin() {
  const [properties, setProperties] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(null);
  const [nextPage, setNextPage] = useState(null);
  const [propertyCount, setPropertyCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchProperties(`http://127.0.0.1:8000/api/basic_users/?page=1&page_size=10`);
  }, []);

  const fetchProperties = async (url) => {
    try {
      const accessToken = localStorage.getItem('access_token');


      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
  
      // If response status is 401, redirect to login page
      if (response.status === 401) {
        // Perform redirection to login page
        window.location.href = '/login'; // Change '/login' to your actual login page route
        return; // Exit function to prevent further execution
      }
      const data = await response.json();
      setProperties(data.results);
      setPreviousPage(data.previous);
      setNextPage(data.next);
      setPropertyCount(data.count);
      setTotalPages(Math.ceil(data.count / data.results.length));
    } catch (error) {
      console.error('Error fetching properties:', error);
    }
  };

  const handleNextPage = () => {
    if (nextPage) {
      setCurrentPage(currentPage + 1);
      fetchProperties(nextPage);
    }
  };

  const handlePreviousPage = () => {
    if (previousPage) {
      setCurrentPage(currentPage - 1);
      fetchProperties(previousPage);
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchProperties(`http://127.0.0.1:8000/api/basic_users/?page=${pageNumber}&page_size=10&search=${search}`);
  };

  const handleSearchChange = (value) => {
    setSearch(value);
    setCurrentPage(1); 
    fetchProperties(`http://127.0.0.1:8000/api/basic_users/?page=1&page_size=10&search=${value}`);
  };

  const handleProperty = (property) => {
    navigate(`/Employee/${property.id}`);
  };

  const renderPaginationNumbers = () => {
    const maxNumbers = 4;
    const pages = [];

    let startPage = Math.max(1, currentPage - Math.floor(maxNumbers / 2));
    let endPage = Math.min(totalPages, startPage + maxNumbers - 1);
    if (endPage - startPage < maxNumbers - 1) {
      startPage = Math.max(1, endPage - maxNumbers + 1);
    }
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <Link
            className="page-link"
            to="#"
            onClick={() => handlePageClick(i)}
            style={{
              textAlign: 'center',
              marginRight: '5px',
              borderRadius: '50%',
              width: '40px',
              padding: '8px',
              backgroundColor: currentPage === i ? 'red' : 'white',
              color: currentPage === i ? 'white' : 'red',
              cursor: 'pointer',
              display: 'inline-block'
            }}
          >
            {i}
          </Link>
        </li>
      );
    }
    return pages;
  };

  return (
    <div className='Signedin'>
        <div className='card' style={{ margin: '1%', borderColor: 'red', borderRadius:'30px',padding:'10px'}}>
        <Row className=' mt-5 mb-5  '>
          <Col>
          <h4 style={{ marginLeft: '5%', marginTop: '3%',height:'36px' }}> <b>Signed in </b></h4>
          </Col>
          <Col className="d-flex justify-content-end align-items-center ">
            <Form.Control
              id="Search"
              type="text"
              placeholder="Search"
              name="Search"
              value={search}
              onChange={(e) => handleSearchChange(e.target.value)}
              style={{
                width: '50%',
                height: '36px',
              }}
            />
          </Col>
        </Row>
        <div className="table-responsive">
          <Table responsive="xl" >
            <thead>
              <tr>
                <th></th>
                <th>Sl.no.</th>
                <th>Name</th>
                <th>Mobile number</th>
                <th>Email</th>
                <th>Registered on</th>
              </tr>
            </thead>
            <tbody>
              {properties.map((property, index) => (
                <tr key={property.id} onClick={() => handleProperty(property)} style={{cursor:'pointer'}} >
                  <td></td>
                  <td>{index + 1 + (currentPage - 1) * 10}</td>
                  <td >{property.username}</td>
                  <td>{property.phone}</td>
                  <td>{property.email}</td>
                  <td>{property.registered_on}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <div className='d-flex justify-content-center mt-3'>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className={`page-item ${!previousPage ? 'disabled' : ''}`}>
              <Button aria-label="Previous" onClick={handlePreviousPage}>
                <span><FaAnglesLeft style={{ color: 'red' }} /></span>
              </Button>
            </li>
            {renderPaginationNumbers()}
            <li className={`page-item ${!nextPage ? 'disabled' : ''}`}>
              <Button aria-label="Next" onClick={handleNextPage}>
                <span><FaAnglesRight style={{ color: 'red' }} /></span>
              </Button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Signedin;
