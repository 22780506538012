import React from 'react';
import p1 from'../Images/p1.png';
import img1 from '../Images/pl1.png';
import img2 from '../Images/la1.png';
import img4 from '../Images/rs1.png';
import img3 from '../Images/co1.png';
export const Curate = [{
    
         id:1,
         
      
        title:"Plots",
        feet:"3000+ properties",
    
    thumbnail: img1
},{

    id:2,

     title:"Lands",
     feet:"3000+ properties",

 thumbnail: img2
},{
    
    id:3,
 
     title:"Residential Properties",
     feet:"3000+ properties",

 thumbnail: img4
},
{
    
    id:4,
   

     title:"Commercial Properties",
     feet:"300+ properties",

 thumbnail: img3
},



];

